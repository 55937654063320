<template>
    <div>
        <v-card>
            <v-card-title>
                Гостевые настройки
                <v-btn depressed class="ml-3" :to="{name: 'merchantAdd'}">
                    <v-icon left> add </v-icon>
                    Добавить
                </v-btn>
            </v-card-title>
            <v-card-text>
                <MerchantsTable/>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
    import MerchantsTable from './MerchantsTable.vue'
    import { mapActions } from "vuex"
    export default{
        components:{
            MerchantsTable,
        },
        methods: mapActions("merchants",["fetchMerchants"]),
        beforeMount(){
            this.fetchMerchants()
        }
    }
</script>