<template>
    <div>
        <v-card>
            <v-card-title>
                Гостевые настройки
                <v-btn depressed class="ml-3" @click="addCarDialogClick()">
                    <v-icon left> add </v-icon>
                    Добавить
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-data-table :headers="headers" :items="cars" :loading="isLoading">
                    <template v-slot:item.action="{ item }">
                        <v-btn icon color="red" @click="askDeleteClick(item)">
                            <v-icon>delete_forever</v-icon>
                        </v-btn>
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :to="{ name: 'merchants' }" text>Назад</v-btn>
            </v-card-actions>
        </v-card>
        <v-dialog v-model="dialogDelete" max-width="290">
            <v-card v-if="carToDelete">
                <v-card-title class="headline">Удалить ГРНЗ?</v-card-title>
                <v-card-text>ГРНЗ {{ carToDelete.plate }} будет удалено</v-card-text>
            </v-card>
            <v-card-actions>
                <v-spacer />
                <v-btn @click="dialogDelete = false" text>Отмена</v-btn>
                <v-btn color="red" @click="deleteClick" text>Да</v-btn>
            </v-card-actions>
        </v-dialog>
        <v-dialog v-model="dialogAdd" max-width="290">
            <v-card>
                <v-card-title class="headline">Добавление ГРНЗ</v-card-title>
                <v-text-field class="mx-4" label="ГРНЗ" outlined v-model="car.plate"></v-text-field>
            </v-card>
            <v-card-actions>
                <v-spacer />
                <v-btn @click="dialogAdd = false" text>Отмена</v-btn>
                <v-btn color="blue" @click="addCarClick" text>Добавить</v-btn>
            </v-card-actions>
        </v-dialog>
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
export default {
    props: ["id"],
    data() {
        return {
            headers: [
                { text: "ГРНЗ", value: "plate" },
                { text: "Действие", value: "action" },],
            dialogDelete: false,
            dialogAdd: false,
            carToDelete: null,
            car: {
                merchantId: "",
                plate: ""
            },
        }
    },
    methods: {
        askDeleteClick(merchantCar) {
            this.carToDelete = merchantCar;
            this.dialogDelete = true;
        },
        deleteClick() {
            this.deleteCar(this.carToDelete).then(() => this.dialogDelete = false)
        },
        addCarDialogClick() {
            this.dialogAdd = true;
        },
        addCarClick() {
            const car = Object.assign({}, this.car);
            car.merchantId = this.id;
            this.addCar(car).then(() => this.dialogAdd = false);
        },
        ...mapActions("merchants", ["deleteCar", "addCar", "fetchCars"]),
    },
    computed: {
        ...mapState({
            cars: (state) => state.merchants.cars,
            isLoading: (state) => state.progressBar.isLoading,
        }),
    },
    created() {
        if (this.id) {
            this.fetchCars(this.id)
        }
    }
}
</script>