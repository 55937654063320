<template>
  <div class="mt-3">
    <v-card class="px-4">
      <v-row>
        <v-col cols="4">
          <v-row>
            <v-col cols="6">
              <v-text-field label="Год" v-model="year"></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2">
          <v-btn color="primary" @click="apply">Применить</v-btn>
        </v-col>
      </v-row>
    </v-card>

    <v-card v-for="report in reports" :key="report.month" class="mt-8">
      <v-card-title>{{ formatMonth(report.month) }}</v-card-title>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th>Продолжительность парковки</th>
              <th>Количество</th>
              <th>Оборот</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="dataItem in report.data" :key="dataItem.totalMinutes">
              <td>{{ formatTotalMinutes(dataItem.totalMinutes) }}</td>
              <td>{{ dataItem.totalCount }}</td>
              <td>{{ dataItem.totalAmount | money }} ₸</td>
            </tr>
            <tr>
              <td>Сумма</td>
              <td>
                {{
                  report.data.map((x) => x.totalCount).reduce((x, y) => x + y)
                }}
              </td>
              <td>
                {{
                  report.data.map((x) => x.totalAmount).reduce((x, y) => x + y)
                    | money
                }}
                ₸
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";

export default {
  data: () => ({
    year: moment().year(),
    reports: [],
  }),

  methods: {
    formatMonth(date) {
      return moment(date).format("MMMM YYYY");
    },

    formatTotalMinutes(value) {
      if (value === 15) return "15 мин";
      if (value === 60) return "1 ч";
      if (value === 120) return "2 ч";
      if (value === 180) return "3 ч";
      if (value === 240) return "4 ч";
      if (value === 300) return "5 ч";
      if (value === 360) return "6 ч";
      if (value === 720) return "12 ч";
      if (value === 1440) return "1 д";
      return value;
    },

    apply() {
      axios
        .get(`${process.env.VUE_APP_API_BASE_URL}/api/reports`, {
          params: { year: +this.year },
        })
        .then((r) => {
          this.reports = r.data;
        });
    },
  },
};
</script>