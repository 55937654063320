<template>
  <div>
    <v-row align="center" justify="center">
      <v-col><v-text-field :value="search" append-icon="mdi-magnify" label="Поиск" single-line solo hide-details
          @input="onSearch" />
      </v-col>
      <v-col>
        <v-btn icon @click="onClear" elevation="3">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table :headers="headers" :items="passes" :loading="isLoading">
      <template v-slot:item.createDate="{ item }">
        <span>{{ item.createDate | date }}</span>
      </template>
      <template v-slot:item.expireDate="{ item }">
        {{ item.expireDate | date }}
      </template>
      <template v-slot:item.isActive="{ item }">
        <span>{{ item.isActive ? "Да" : "Нет" }}</span>
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn icon color="primary" :to="{ name: 'passEdit', params: { id: item.id } }">
          <v-icon>edit</v-icon>
        </v-btn>
        <v-btn icon color="red" @click="askToDeleteClick(item)">
          <v-icon>delete_forever</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog" max-width="290">
      <v-card v-if="passToDelete">
        <v-card-title class="headline">Удалить пропуск?</v-card-title>
        <v-card-text>Пропуск {{ passToDelete.plate || passToDelete.ean13 }} будет удален
          из системы</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false">Отмена</v-btn>
          <v-btn color="error" text @click="deleteClick">Да</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      headers: [
        { text: "ГРНЗ", value: "plate" },
        { text: "EAN13", value: "ean13" },
        { text: "Дата создания", value: "createDate" },
        { text: "Дата истечения", value: "expireDate" },
        { text: "Активен", value: "isActive" },
        { text: "Компания", value: "company" },
        { text: "ФИО", value: "fullName" },
        { text: "Комментарий", value: "comment" },
        { text: "Действие", value: "action" },
      ],
      dialog: false,
      passToDelete: null,
    };
  },
  computed: {
    ...mapState({
      search: (state) => state.passes.search,
      isLoading: (state) => state.progressBar.isLoading,
    }),
    ...mapGetters("passes", {
      passes: "filteredPasses"
    }),
  },
  methods: {
    askToDeleteClick(pass) {
      this.passToDelete = pass;
      this.dialog = true;
    },
    deleteClick() {
      this.deletePass(this.passToDelete).then(() => (this.dialog = false));
    },
    onSearch(value) {
      this.filterPasses(value.toUpperCase())
    },
    onClear() {
      this.filterPasses('')
    },
    ...mapActions("passes", ["deletePass", "filterPasses"]),
  },
};
</script>