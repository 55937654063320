<template>
  <v-card v-if="merchantId">
    <v-card-title>
      QR коды
      <v-spacer></v-spacer>
      <v-btn
        :disabled="!selected.length"
        @click="exportToZip(selected.map((i) => i.id))"
        icon
        color="blue darken-4"
      >
        <v-icon>file_download</v-icon>
      </v-btn>
      <v-btn @click="addDialog = true" icon color="red">
        <v-icon>add</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-data-table
        :headers="headers"
        :footer-props="footerProps"
        :items="qrCodes"
        item-key="id"
        show-select
        :value="selected"
        @input="onSelect"
      >
        <template v-slot:item.isIssued="{ item }">
          {{ item.isIssued ? "Выпущен" : "Создан" }}
        </template>
        <template v-slot:item.createDate="{ item }">
          {{ item.createDate | fulldate }}
        </template>
        <template v-slot:item.icon="{}">
          <v-icon aria-hidden="false">qr_code</v-icon>
        </template>
      </v-data-table>
    </v-card-text>

    <v-dialog v-model="addDialog" width="400">
      <QrCodeAdd @close="addDialog = false" />
    </v-dialog>
  </v-card>
</template>

<script>
import QrCodeAdd from "./QrCodeAdd";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    QrCodeAdd,
  },
  data() {
    return {
      headers: [
        { text: "Статус", value: "isIssued", align: "start" },
        { text: "Дата создания", value: "createDate", align: "start" },
        { text: "", value: "icon", align: "end" },
      ],
      footerProps: {
        "items-per-page-options": [5, 10, 15, 20],
      },
      addDialog: false,
    };
  },
  computed: mapState({
    merchantId: (state) => state.qrCodes.merchantId,
    qrCodes: (state) => state.qrCodes.qrCodes,
    selected: (state) => state.qrCodes.selected,
  }),
  methods: {
    onSelect(data) {
      this.selectQrCodes(data);
      console.log(data);
    },
    ...mapActions("qrCodes", ["exportToZip", "selectQrCodes"]),
  },
};
</script>