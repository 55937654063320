var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(" Выписка "),_c('v-spacer'),_c('v-btn',{staticClass:"mr-5",attrs:{"depressed":""},on:{"click":_vm.downloadCsv}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v("save_alt")]),_vm._v(" Скачать CSV ")],1),_c('div',[_c('div',{staticClass:"font-weight-light"},[_vm._v(" Сумма платежей: "+_vm._s(_vm._f("money")(_vm.totalAmount))+" ₸ ")])])],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.statements,"items-per-page":_vm.pageCount,"footer-props":_vm.footerProps,"server-items-length":_vm.totalCount,"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.entryDateTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("fulldate")(item.entryDateTime))+" ")]}},{key:"item.exitDateTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("fulldate")(item.exitDateTime))+" ")]}},{key:"item.minutes",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("duration")(item.minutes))+" ")]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("money")(item.amount))+" ₸ ")]}},{key:"item.paymentType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getPaymentType(item))+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }