<template>
    <div>
        <v-row align="center" justify="center">
            <v-col>
                <v-text-field :value="search" append-icon="mdi-magnify" label="Поиск" single-line solo hide-details
                    @input="onSearch" />
            </v-col>
            <v-col>
                <v-btn icon @click="onClear" elevation="3">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-data-table :headers="headers" :items="merchants" :loading="isLoading">
            <template v-slot:item.isEnabled="{ item }">
                <span>{{ item.isEnabled ? "Да" : "Нет" }}</span>
            </template>
            <template v-slot:item.action="{ item }">
                <v-container>
                    <v-row>
                        <v-col>
                            <v-btn icon color="primary" :to="{ name: 'merchantEdit', params: { merchantToEdit: item } }">
                                <v-icon>edit</v-icon>
                            </v-btn></v-col>
                        <v-col><v-btn icon color="green" :to="{ name: 'merchantCars', params: { id: item.id } }">
                                <v-icon>mdi-car-settings</v-icon>
                            </v-btn> </v-col>
                        <v-col><v-btn icon color="red" @click="askDeleteClick(item)">
                                <v-icon>delete_forever</v-icon>
                            </v-btn></v-col>
                    </v-row>
                </v-container>
            </template>
        </v-data-table>
        <v-dialog v-model="dialog" max-width="290">
            <v-card v-if="merchantToDelete">
                <v-card-title class="headline">Удалить клиента?</v-card-title>
                <v-card-text>Клиент {{ merchantToDelete.phoneNumber }} будет удален</v-card-text>
            </v-card>
            <v-card-actions>
                <v-spacer />
                <v-btn @click="dialog = false" text>Отмена</v-btn>
                <v-btn color="red" @click="deleteClick" text>Да</v-btn>
            </v-card-actions>
        </v-dialog>
    </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
    data() {
        return {
            headers: [
                { text: "Номер телефона", value: "phoneNumber" },
                { text: "Кол-во гостевых ТС", value: "guestCarsCount" },
                { text: "Кол-во бесплатных минут", value: "guestFreeMinutes" },
                { text: "Включено", value: "isEnabled" },
                { text: "Действие", value: "action" },],
            dialog: false,
            merchantToDelete: null,
        }
    },
    methods: {
        askDeleteClick(merchant) {
            this.merchantToDelete = merchant;
            this.dialog = true;
        },
        deleteClick() {
            this.deleteMerchant(this.merchantToDelete.id);
            this.dialog = false;
        },
        onSearch(value) {
            this.filterMerchants(value.toUpperCase())
        },
        onClear() {
            this.filterMerchants('')
        },
        ...mapActions("merchants", ["deleteMerchant", "filterMerchants"]),
    },
    computed: {
        ...mapState({
            search: (state) => state.merchants.search,
            isLoading: (state) => state.progressBar.isLoading,
        }),
        ...mapGetters("merchants", {
            merchants: "filteredMerchants"
        }),
    },
}
</script>