<template>
  <v-row>
    <v-col cols="3">
      <Merchants />
    </v-col>
    <v-col cols="9">
      <QrCodes />
    </v-col>
  </v-row>
</template>

<script>
import Merchants from "./Merchants";
import QrCodes from "./QrCodes";

export default {
  components: { Merchants, QrCodes },
};
</script>