<template>
  <div>
    <v-card>
      <v-card-title>Терминалы</v-card-title>
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Название</th>
                <th class="text-left">Дата создания смены</th>
                <th class="text-left">Кол-во 50 монет на сдачу</th>
                <th class="text-left">Кол-во 100 монет на сдачу</th>
                <th class="text-left">Кол-во 500 купюр</th>
                <th class="text-left">Кол-во 1000 купюр</th>
                <th class="text-left">Кол-во 50 монет</th>
                <th class="text-left">Кол-во 100 монет</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in terminals" :key="item.name">
                <td>{{ item.name }}</td>
                <td>{{ item.createDate | date }}</td>
                <td>{{ item.coin50Change }}</td>
                <td>{{ item.coin100Change }}</td>
                <td>{{ item.note500Received }}</td>
                <td>{{ item.note1000Received }}</td>
                <td>{{ item.coin50Received }}</td>
                <td>{{ item.coin100Received }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  computed: {
    ...mapState({
      terminals: (state) => state.terminals.terminals,
    }),
  },
  methods: mapActions("terminals", ["fetchTerminals"]),
  mounted() {
    this.fetchTerminals();
  },
};
</script>

<style>
</style>