<template>
  <div>
    <v-card>
      <v-card-title>
        Пропуски
        <v-btn depressed class="ml-3" :to="{ name: 'passAdd' }">
          <v-icon left> add </v-icon>
          Добавить
        </v-btn>
        <v-btn depressed class="ml-3" @click="downloadCsv">
          <v-icon left dark>save_alt</v-icon>
          Скачать CSV
        </v-btn>
      </v-card-title>
      <v-card-text>
        <PassesTable />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import PassesTable from "./PassesTable";

import { mapActions } from "vuex";

export default {
  components: {
    PassesTable,
  },
  methods: mapActions("passes", ["fetchPasses", "downloadCsv"]),
  beforeMount() {
    this.fetchPasses();
  },
};
</script>