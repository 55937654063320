<template>
  <v-card>
    <v-card-title
      class="headline grey lighten-2 d-flex justify-space-between"
      primary-title
      >Генерация QR кодов</v-card-title
    >

    <v-card-text class="text--primary mt-3">
      <v-text-field
        v-model.number="count"
        type="number"
        label="Кол-во"
        outlined
      ></v-text-field>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text @click="submit" :disabled="count < 1" color="red"
        >Создать</v-btn
      >
      <v-btn text @click="close">Отмена</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      count: 0,
    };
  },
  methods: {
    submit() {
      this.addQrCodes(this.count).then(() => {
        this.showAlert({ message: "QR коды успешно сгенерированы" });
        this.close();
      });
    },
    close() {
      this.$emit("close");
      this.count = 0;
    },
    ...mapActions("qrCodes", ["addQrCodes"]),
    ...mapActions(["showAlert"]),
  },
};
</script>

<style>
</style>