<template>
  <v-card class="px-4">
    <v-row>
      <v-col cols="4">
        <v-row>
          <v-col cols="6">
            <v-menu
              v-model="menuDateFrom"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateFrom"
                  label="С"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dateFrom"
                first-day-of-week="1"
                @input="menuDateFrom = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6">
            <v-menu
              v-model="menuDateTo"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateTo"
                  label="По"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dateTo"
                first-day-of-week="1"
                @input="menuDateTo = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="4">
        <v-row>
          <v-col cols="6">
            <v-text-field
              label="Минут, мин"
              type="number"
              v-model="minMinutes"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              label="Минут, макс"
              type="number"
              v-model="maxMinutes"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="4">
        <v-row>
          <v-col cols="6">
            <v-text-field label="ГРНЗ" v-model="plate"></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-select
              v-model="paymentTypes"
              :items="paymentTypeOptions"
              item-text="name"
              item-value="value"
              :menu-props="{ maxHeight: '400' }"
              label="Тип оплаты"
              multiple
            >
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0">{{ item.name }}</span>
                <span v-if="index === 1" class="grey--text caption">
                  (+{{ paymentTypes.length - 1 }} еще)
                </span>
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="2">
        <v-btn color="primary" @click="apply">Применить</v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import moment from "moment";
import { mapState, mapActions } from "vuex";

export default {
  data: () => ({
    menuDateFrom: false,
    menuDateTo: false,

    dateFrom: "",
    dateTo: "",
    plate: "",
    paymentTypes: [],
    minMinutes: "",
    maxMinutes: "",

    paymentTypeOptions: [
      { name: "Terminal", value: 1 },
      { name: "Пропуск", value: 2 },
      { name: "Kaspi", value: 3 },
      { name: "Halyk", value: 4 },
      { name: "Manual", value: 5 },
      { name: "QR код", value: 6 },
      { name: "Telegram гость", value: 7 },
    ],
  }),
  computed: {
    ...mapState({
      dateFromState: (state) =>
        moment(state.statements.criteria.dateFrom).format("YYYY-MM-DD"),
      dateToState: (state) =>
        moment(state.statements.criteria.dateTo).format("YYYY-MM-DD"),
      paymentTypesState: (state) => state.statements.criteria.paymentTypes,
    }),
  },
  methods: {
    apply() {
      this.filter({
        dateFrom: moment(this.dateFrom).startOf('date').toDate(),
        dateTo: moment(this.dateTo).endOf('date').toDate(),
        plate: this.plate,
        paymentTypes: this.paymentTypes,
        minMinutes: +this.minMinutes,
        maxMinutes: +this.maxMinutes,
      });
    },
    ...mapActions("statements", ["filter"]),
  },
  mounted() {
    this.dateFrom = this.dateFromState;
    this.dateTo = this.dateToState;
    this.paymentTypes = this.paymentTypesState;
  },
};
</script>