<template>
    <v-card>
        <v-card-title class="headline">
            {{ this.merchantToEdit ? "Редактирование" : "Добавление" }} клиента
        </v-card-title>
        <v-form ref="form">
            <v-card-text>
                <v-alert v-if="message" type="error">{{ message }}</v-alert>
                <v-row>
                    <v-col>
                        <v-text-field prefix="+" label="Номер телефона" outlined v-model="merchant.phoneNumber"
                            :rules="[rules.phoneNumberLengthMin, rules.number]" />
                    </v-col>
                    <v-col>
                        <v-text-field label="Кол-во гостевых ТС" type="number" min="0" outlined
                            v-model="merchant.guestCarsCount" :rules="[rules.number]" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field label="Кол-во бесплатных минут для каждого ТС" type="number" min="0" outlined
                            v-model="merchant.guestFreeMinutes" :rules="[rules.number]" />
                    </v-col>
                    <v-col>
                        <v-checkbox label="Включено" v-model="merchant.isEnabled" />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :to="{ name: 'merchants' }" text>Отмена</v-btn>
                <v-btn @click="onSaveMerchant" color="primary">Сохранить</v-btn>
            </v-card-actions>
        </v-form>
    </v-card>
</template>

<script>
import commonMixin from '@/mixins/commonMixins'
import { mapActions } from 'vuex'

export default {
    props: ["merchantToEdit"],
    mixins: [commonMixin],
    data() {
        return {
            merchant: {
                phoneNumber: "",
                guestCarsCount: 0,
                guestFreeMinutes: 0,
                isEnabled: true,
            },
            message: "",
            rules: {
                phoneNumberLengthMin: value => value.length >= 11 || 'Номер телефона введен неверно',
                number: value => {
                    const pattern = /\d/
                    return pattern.test(value) || 'Введите число'
                },
            },
        }
    },
    methods: {
        onSaveMerchant() {
            if (this.$refs.form.validate()) {
                const merchant = Object.assign({}, this.merchant)
                merchant.guestCarsCount = parseInt(merchant.guestCarsCount)
                merchant.guestFreeMinutes = parseInt(merchant.guestFreeMinutes)
                merchant.isEnabled = merchant.isEnabled == 1 ? true : false
                const promise = this.merchantToEdit ? this.editMerchant(merchant) : this.addMerchant(merchant);
                promise.then(() => this.$router.push({ name: "merchants" }));
            }
        },
        ...mapActions("merchants", ["getMerchant", "editMerchant", "addMerchant"]),
    },
    created() {
        if (this.merchantToEdit) {
            this.merchant = this.merchantToEdit
        }
    }
}
</script>